<template>
	<div>
		<PageTitle title="个人账户" fixed :backTo="'/accountJudgment/' + selectIndex +'/0/0'"></PageTitle>
		<div style="position: relative;width: 85%;margin: 15px auto auto auto;">
			<!-- <van-button type="primary" block style="width: 40%;" @click="setDefault">设为默认账户</van-button> -->
			<List :width="100">
				<ListItem :leftWidth="0" :rightWidth="100">
					<template #top>
						<img v-show="verification==1" src="../assets/verification-green.png">
						<p style="text-align: center">个人账户</p>
					</template>
					<template v-slot:right>
						<div class="margintb">
							<p>就诊卡号：{{ idDetail.ckhm | filterCardNum }}</p>
							<p class="width50">姓名：{{ idDetail.brxm }}</p>
							<p class="width50">性别：{{ idDetail.brxb }}</p>
							<p class="width50">年龄：{{ idDetail.nl }}</p>
							<p class="width50">民族：{{ idDetail.mz }}</p>
							<p>身份证号：{{ idDetail.sfzh }}</p>
						</div>
						<!--						<div class="margintb">-->
						<!--							<p>性质：{{idDetail.xzmc}}</p>-->
						<!--						</div>-->
						<div class="margintb">
							<p>住址：{{ idDetail.address }}</p>
						</div>
						<div class="margintb">
							<p>绑定手机号：{{ idDetail.sjhm }}</p>
						</div>
						<!--            <div class="margintb">-->
						<!--              <p>账户余额：{{ idDetail.zhye }}元</p>-->
						<!--              <p>可退金额：{{ idDetail.ktye }}元</p>-->
						<!--            </div>-->
					</template>
				</ListItem>
			</List>
			<!-- <footer>
        <van-button type="primary" block size="small" @click="unbind">解绑</van-button>
        <van-button type="primary" block size="small" @click="toPay">充值</van-button>
        <van-button type="primary" block size="small" :to="'/refund/' + selectIndex+'/'+idDetail.ckhm">退款
        </van-button>
        <van-button type="primary" block size="small"
          :to="'/check/' + idDetail.ckhm+'/'+idDetail.brxm+'/'+idDetail.xzmc+'/0'">账单明细</van-button>
      </footer> -->
			<van-button type="primary" block style="width: 100%;" @click="setDefault">设为默认账户</van-button>
			<van-button type="primary" block style="width: 100%;margin-top: 10px" @click="unbind">解绑</van-button>
		</div>
	</div>
</template>

<script>
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import {
		SetDefaultAccount,
		AccountDebind,
		QueryAccount
	} from '@/api/user.js';

	export default {
		components: {
			PageTitle,
			List,
			ListItem,
		},
		data() {
			return {
				idDetail: {
					id: 0,
					ckhm: "",
					brxm: "",
					brxb: "",
					sfzh: "",
					brxz: "",
					xzmc: "",
					zhye: 0,
					ktye: 0,
					mrzh: "",
					sjhm: "",
					address: "",
					nl: "",
					birthday: "",
					mz: "",
					zhyxpb: "",
					sfzpb: "",
					phonePB: "",
					namepb: "",
					srbrxm: "",
					srsfzh: "",
					srphone: ""
				},
				verification: 2,
				selectIndex: this.$route.params.selectIndex || localStorage.getItem('selectIndex'),
			}
		},
		mounted() {
			QueryAccount(3, this.$route.params.cardId, this.$store.getters.userInfo.UserId).then(
				(data) => {
					if (data.length) {
						this.idDetail = data[0];
						this.verification = data[0].mrzh;
					} else {
						this.$dialog.alert({
							message: 'error', //提示内容
							confirmButtonText: "当前账户的默认账户存在异常，请检查",
						}).then(() => {
							window.location.replace(window.location.origin + window.location.search +
								"#/accountJudgment/0/0/0");
						});
					}
				},
				() => {}
			);
		},
		methods: {
			setDefault() {
				// if (this.idDetail.brxz == 27) {
				// 	this.$dialog.alert({
				// 		title: '温馨提醒',
				// 		message: '参保地为温州地区以外的人员，暂时不能使用云医院复诊开药，敬请期待',
				// 	}).then(() => {});
				// } else {
				SetDefaultAccount(this.idDetail.ckhm, this.$store.getters.userInfo.UserId).then(
					() => {
						this.$store.SaveSocket = '';
						this.$dialog.alert({
							title: '温馨提醒',
							message: '设置当前账号为默认账号成功',
						}).then(() => {
							// on confirm
							this.verification = 1;
							this.idDetail.mrzh = 1;
						});
					},
					() => {}
				);
				// }
			},
			unbind() {
				this.$dialog.confirm({
					title: '温馨提醒',
					message: '解绑后，该账户将不再显示\n如果想要再次使用\n需要重新绑定',
					confirmButtonText: '取消',
					cancelButtonText: '继续'
				}).then(() => {
					// on right
				}).catch(() => {
					// on left
					AccountDebind(this.idDetail.ckhm, this.$store.getters.userInfo.UserId).then(
						() => {
							this.$dialog.alert({
								title: '温馨提醒',
								message: '此账号已解绑成功',
							}).then(() => {
								// on confirm
								this.$router.go(-1);
								// this.$router.push("/accountJudgment/0/0/0");
							});
						},
						() => {}
					);
				});
			},
			toPay() {
				localStorage.setItem('payDepartmentId', '0');
				localStorage.setItem('payDoctorId', '0');
				localStorage.setItem('payCardId', this.idDetail.ckhm);
				localStorage.setItem('payPreRoute', 'accountInformation');
				localStorage.setItem('selectIndex', this.selectIndex);
				this.$router.push('/recharge/1');
			}
		}
	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	footer .van-button {
		width: 23%;
		display: inline-block;
		margin: auto 1%;
	}

	.infoContainer {
		margin-top: 15px;
		padding: 10px;

		p {
			font-size: @font-size-md;
			margin: 0;
		}

		img {
			position: absolute;
			top: -10px;
			right: -10px;
			width: 60px;
		}

		.margintb {
			margin: 10px auto 10px 5px;
		}

		.width50 {
			width: 50%;
			display: inline-block;
		}

		.top p {
			text-align: center;
			margin: 0 0 10px;
		}

		.left .van-button,
		.right .van-button {
			margin: 0 0 10px;
		}

		.van-cell {
			border: 1.5px solid @green;
			padding: 4px 16px;
			border-radius: 8px;
			box-shadow: 0px 0px 5px 0px @gray-6 inset;

		}
	}
</style>
